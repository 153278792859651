<template>
  <div class="app flex-row align-items-center" v-if='this.isErrorPage'>
    <b-jumbotron class='bg-transparent'>
      <template #header>Oops, There is Some Error</template>
      <div class='p-3'>
        <h5 class='card-title mb-0 font-weight-bold' v-if='this.message'>{{ this.message }}</h5>
        <h5 class='card-title mb-0 font-weight-bold' v-else>Error Not Found, Please Kindly Try Again</h5>
      </div>

      <router-link class="btn btn-sm btn-dark  " size="md" :to="{ path: '/' }">
        <em class="fa fa-backward btn-sm btn-dark "></em> Return To Home Page
      </router-link>
    </b-jumbotron>
  </div>
  <div class="app flex-row align-items-center" v-else>
    <div class="container mt-3">
      <b-row class="row justify-content-center">
        <b-col md="6" sm="0" class="left mt-5 display-mobile">
          <img style="height: 75%" src="../../assets/img/design.png" alt="admin@bootstrapmaster.com" />
        </b-col>
        <b-col md="6" class="right">
          <b-card-group>
            <b-card-body class="background-mobile">
              <b-row align-v="center">
                <b-col class="text-center">
                  <img src="../../assets/img/gramedia_digital.png" alt="admin@bootstrapmaster.com" class="m-5" />
                </b-col>
              </b-row>
              <b-form @submit.prevent="actionReset">
  <h1 class="h2 text-center">Change Password</h1>
  <div class="row">
    <div class="col-sm-10">
      <b-form-group label="New Password (*)" class="mt-2 mb-0">
        <b-form-input :type="showPassword ? 'text' : 'password'" class="form-control"
          placeholder="Password" v-model="Form.password" :state="statePassword(Form.password)" required />
      </b-form-group>
    </div>
    <div class="col-sm-2 mt-5" @click="togglePassword">
      <b-icon v-if="showPassword" icon="eye"></b-icon>
      <b-icon v-else icon="eye-slash"></b-icon>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-10 mt-8">
      <b-form-group label="Confirm New Password Again (*)" class="mt-5 mb-0">
        <b-form-input :type="showPassword ? 'text' : 'password'" class="form-control" placeholder="Password"
          v-model="Form.passwordVerify" :state="stateConfirmPassword(Form.password, Form.passwordVerify)"
          required />
          <small class="text-danger mt-1"
        v-if="Form.passwordVerify && !stateConfirmPassword(Form.password, Form.passwordVerify)">
        *Passwords do not match
      </small>
      </b-form-group>
    </div>
    <div class="col-sm-2 margin_custom mt-5" @click="togglePassword">
      <b-icon v-if="showPassword" icon="eye"></b-icon>
      <b-icon v-else icon="eye-slash"></b-icon>
    </div>
  </div>
  <b-row>
    <b-col>
      <br />
      <b-button :disabled="isDisable" lg="4" type="submit" variant="primary" class="px-4 col-sm-10">
        Reset
        <i class="fa fa-sign-in" aria-hidden="true"></i>
        <b-spinner v-if="!isDisable && isLoading" type="grow" label="Spinning"></b-spinner>
      </b-button>
    </b-col>
  </b-row>
</b-form>

            </b-card-body>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "resetPassword",
  data() {
    return {
      Form: {},
      isActive: false,
      showPassword: false,
    };
  },
  computed: {
    ...mapState({
      success: (state) => state.forgetPassword.isSuccess,
      message: (state) => state.forgetPassword.successMessage,
      isLoading: (state) => state.forgetPassword.isLoading,
      statusCode: (state) => state.forgetPassword.statusCode,
      token: (state) => state.forgetPassword.token,
      isError: (state) => state.forgetPassword.isError,
      isErrorPage: (state) => state.forgetPassword.isErrorPage,
      isPage: (state) => state.forgetPassword.isPage,
    }),
    isDisable() {
      return !(this.Form.password && this.Form.password.length > 4 && this.Form.passwordVerify && this.Form.passwordVerify.length > 4);
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Reset Password | Content Management System Ebooks Gramedia.com';
      }
    },
    success: function () {
      if (this.success) {
        this.$swal
          .fire({
            title: `Password Changed`,
            text: this.message,
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Close",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ path: "/" });
            }
          });
      }
    },
    isError: function () {
      if (this.isError) {
        this.messageAlert("error", this.message)
      }
    },

  }, created() {
    let data = this.$route.params.data;
    this.data = data;
    this.checkToken({ data });
  }, mounted() {
    this.actionCheck();
  },
  methods: {
    ...mapActions("forgetPassword", ["checkToken", "resetPassword"]),
    actionCheck() {
      let data = this.$route.params.data;
      this.data = data;
      this.checkToken({ data });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    statePassword(password) {
      if (!password) return null;
      if (password?.length < 8) return false;
      return true;
    },
    stateConfirmPassword(password, confirmPassword) {
      if (!confirmPassword) return null;
      if (password?.length < 8) return false;
      if (password !== confirmPassword) return false;
      return true;
    },
    actionReset() {
      // Verify that the passwords match
      if (this.Form.password !== this.Form.passwordVerify) {
        this.showAlert({
          success: false,
          message: "Password Must Be Same",
        });
        return;
      } else {
        let payload = {
          newPassword: this.Form.password,
          data: this.$route.params.data,
        };
        this.resetPassword(payload)
      }
    }, messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    showAlert(data) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon: data.success ? "success" : "error",
        title: data.message,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
  },
};
</script>
<style lang="css" scoped>
.center {
  width: 500px;
  height: 400px;
  top: 20%;
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}
.margin_custom {
    position: relative;
    top: 30px;
}
.form-control {
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
}

input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

@media (min-width: 360px) and (max-width: 768px) {
  .background-mobile {
    background-color: #fff;
  }

  .display-mobile {
    display: none;
  }

  .custom_forgot_pass {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
  }

  .custom_forgot_pass div:last-child {
    width: 20%;
  }
}
</style>
