var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.isErrorPage ? _c('div', {
    staticClass: "app flex-row align-items-center"
  }, [_c('b-jumbotron', {
    staticClass: "bg-transparent",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm._v("Oops, There is Some Error")];
      },
      proxy: true
    }], null, false, 2855500917)
  }, [_c('div', {
    staticClass: "p-3"
  }, [this.message ? _c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(_vm._s(this.message))]) : _c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v("Error Not Found, Please Kindly Try Again")])]), _c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "size": "md",
      "to": {
        path: '/'
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward btn-sm btn-dark"
  }), _vm._v(" Return To Home Page ")])], 1)], 1) : _c('div', {
    staticClass: "app flex-row align-items-center"
  }, [_c('div', {
    staticClass: "container mt-3"
  }, [_c('b-row', {
    staticClass: "row justify-content-center"
  }, [_c('b-col', {
    staticClass: "left mt-5 display-mobile",
    attrs: {
      "md": "6",
      "sm": "0"
    }
  }, [_c('img', {
    staticStyle: {
      "height": "75%"
    },
    attrs: {
      "src": require("../../assets/img/design.png"),
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('b-col', {
    staticClass: "right",
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-group', [_c('b-card-body', {
    staticClass: "background-mobile"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "m-5",
    attrs: {
      "src": require("../../assets/img/gramedia_digital.png"),
      "alt": "admin@bootstrapmaster.com"
    }
  })])], 1), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.actionReset.apply(null, arguments);
      }
    }
  }, [_c('h1', {
    staticClass: "h2 text-center"
  }, [_vm._v("Change Password")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-10"
  }, [_c('b-form-group', {
    staticClass: "mt-2 mb-0",
    attrs: {
      "label": "New Password (*)"
    }
  }, [_c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "type": _vm.showPassword ? 'text' : 'password',
      "placeholder": "Password",
      "state": _vm.statePassword(_vm.Form.password),
      "required": ""
    },
    model: {
      value: _vm.Form.password,
      callback: function ($$v) {
        _vm.$set(_vm.Form, "password", $$v);
      },
      expression: "Form.password"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-sm-2 mt-5",
    on: {
      "click": _vm.togglePassword
    }
  }, [_vm.showPassword ? _c('b-icon', {
    attrs: {
      "icon": "eye"
    }
  }) : _c('b-icon', {
    attrs: {
      "icon": "eye-slash"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-10 mt-8"
  }, [_c('b-form-group', {
    staticClass: "mt-5 mb-0",
    attrs: {
      "label": "Confirm New Password Again (*)"
    }
  }, [_c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "type": _vm.showPassword ? 'text' : 'password',
      "placeholder": "Password",
      "state": _vm.stateConfirmPassword(_vm.Form.password, _vm.Form.passwordVerify),
      "required": ""
    },
    model: {
      value: _vm.Form.passwordVerify,
      callback: function ($$v) {
        _vm.$set(_vm.Form, "passwordVerify", $$v);
      },
      expression: "Form.passwordVerify"
    }
  }), _vm.Form.passwordVerify && !_vm.stateConfirmPassword(_vm.Form.password, _vm.Form.passwordVerify) ? _c('small', {
    staticClass: "text-danger mt-1"
  }, [_vm._v(" *Passwords do not match ")]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "col-sm-2 margin_custom mt-5",
    on: {
      "click": _vm.togglePassword
    }
  }, [_vm.showPassword ? _c('b-icon', {
    attrs: {
      "icon": "eye"
    }
  }) : _c('b-icon', {
    attrs: {
      "icon": "eye-slash"
    }
  })], 1)]), _c('b-row', [_c('b-col', [_c('br'), _c('b-button', {
    staticClass: "px-4 col-sm-10",
    attrs: {
      "disabled": _vm.isDisable,
      "lg": "4",
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Reset "), _c('i', {
    staticClass: "fa fa-sign-in",
    attrs: {
      "aria-hidden": "true"
    }
  }), !_vm.isDisable && _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Spinning"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }